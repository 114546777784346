$(function () {

    // FORM

    // form fix
    $("form").on("submit", function () {
        var $form = $(this);
        var $files = $form.find("input[type='file']");
        if ($files.length > 0) {
            $files.each(function () {
                var $this = $(this);

                if ($this.val() == "") $this.attr("disabled", "disabled");
            })
        }

        if ($form.data('loading')) {
            Helper.loading({text: 'Ukládám'});
        }
    });



    $(".form-error").off().on("click", function () {
        var $this = $(this);
        $this.siblings("input,textarea").focus();

    });

    $(".form-group.has-error .form-control").on("focus", function () {
        $(this).siblings(".form-error").stop().fadeOut(300);
    }).on("blur", function () {
        $(this).siblings(".form-error").stop().fadeIn(300);
    })

    $(".form-error").each(function () {
        var $this = $(this);
        perc = 100;
        while (perc > 50 && $this[0].scrollWidth > $this.innerWidth()) {
            perc -= 5;
            $this.css({'font-size': perc + '%', 'line-height': '20px'});
        }
    });

    // CHECKBOX
    $(".form-checkbox input[type='checkbox']").off("change").on("change", function () {
        var $this = $(this);
        var $checkbox = $this.closest(".form-checkbox");
        var $input = $checkbox.find("input[type='hidden']");

        $input.val($this.is(":checked") ? 1 : 0);
    });

    // SELECT (multiple relations)
    var multiple_update = function () {

        $(".form-group ul.list-group.select .close").off("click").on("click", function () {
            var $li = $(this).closest(".list-group-item");
            var $ul = $li.closest('.list-group');
            var $input = $li.find('input[type="hidden"]');
            var $select = $li.closest('.form-group').find("select");
            $select.find('option[value="' + $input.val() + '"]').removeAttr('disabled').show();

            if ($select.hasClass('select2-hidden-accessible')) $select.trigger('change');

            $li.remove();

            if ($ul.find(".list-group-item").length == 0) $ul.addClass('hidden');
        });


        $("select.multiple:not([data-tags='true'])").off('change').on("change", function () {

            var $this = $(this);
            var $group = $this.closest('.form-group');
            var $ul = $group.find("ul.list-group");
            var o = '';
            var $option = $this.find("option:selected");

            if ($option.length > 1) $option = $($option[0]);

            if ($option.val() != '') {
                o += '<li class="list-group-item">';
                o += '   <input type="hidden" name="' + $this.data('name') + '[]" value="' + $option.val() + '"/>';
                o += '    ' + $option.text();
                o += '   <a class="close"><i class="fa fa-times"></i></a>';
                o += '</li>';

                $ul.removeClass('hidden');
                $ul.append(o);

                $option.attr('disabled', '').hide();

                $this.find("option:first").prop("selected", true);

            }

            if ($this.hasClass('select2-hidden-accessible')) {
                $this.select2();
            }

            multiple_update();
        });
    }

    multiple_update();


    // FILE UPLOAD

    $(".form-group ul.list-group.select input[type='hidden']").each(function () {
        var $this = $(this);
        var $select = $this.closest('.form-group').find("select");

        if ($select.length == 1) {
            $select.find('option[value="' + $this.val() + '"]').prop('selected', true).trigger('change');
            $this.remove();
        }
        else {
            $this.find("")
        }
    });

    $("select[data-tags='true']").on('change', function () {
        var $this = $(this);
        var $div = $this.closest('.form-select');
        var height = $this.parent().find('.select2-selection').height() + 2;
        $div.css('height', height);
    })

    // DELETE
    var $deletable = $(".form-group ul.list-group.delete, .gallery.delete .gallery-item");
    $deletable.find("input[disabled]").addClass("disabled");

    $deletable.find(".close").off("click").on("click", function () {

        var $this = $(this);
        var $li = $(this).closest(".list-group-item, .gallery-item");
        var $input = $li.find('input.disabled');
        var $inputs = $li.find('input:not(.disabled),select:not(.disabled),textarea:not(.disabled)');

        if ($li.hasClass("disabled")) {
            $li.removeClass("disabled");
            $input.attr("disabled", "true");
            $inputs.removeAttr("disabled");
            $this.find("i.fa").removeClass("fa-check").addClass("fa-times");
        }
        else {
            $li.addClass("disabled");
            $input.removeAttr("disabled");
            $inputs.attr("disabled", "true");
            $this.find("i.fa").addClass("fa-check").removeClass("fa-times");
        }

    })

    // FILE UPLOAD

    $(".form-file input[type='file']").off("change").on("change", function () {
        var $this = $(this);
        var file = $this.val().split("\\").pop();
        var $file = $this.closest('.form-file');
        var $span = $file.find('span');

        if (file !== "") {
            $span.text(file);
            $file.addClass('active');
        }
        else {
            $span.text($span.data('title'));
            $file.removeClass('active');
        }
    });

    $(".form-file .close").off("click").on("click", function () {
        var $this = $(this);
        var $div = $this.closest(".form-file");
        var $input = $div.find("input[type='hidden']");

        if ($div.hasClass("disabled")) {
            $div.removeClass("disabled");
            $input.attr("disabled", "true");
            $this.find("i.fa").removeClass("fa-check").addClass("fa-times");
        }
        else {
            $div.addClass("disabled");
            $input.removeAttr("disabled");
            $this.find("i.fa").addClass("fa-check").removeClass("fa-times");
        }
    });

    $(".chunk-upload-select").each(function () {

        var $this = $(this);
        var $group = $this.closest(".form-group");
        var $form = $this.closest("form");
        var $button = $group.find(".chunk-upload-submit");
        var $list = $group.find(".chunk-upload-files");
        console.log($form.attr('action'));

        var uploader = new plupload.Uploader({
            runtimes : 'html5,flash,silverlight,html4',

            browse_button : $this[0], // you can pass in id...
            container: $group[0], // ... or DOM Element itself

            url : $form.attr('action'),

            file_data_name: 'chunk-file',
            multipart_params: {'_token' : $form.find("input[name='_token']").val(), '_method' : $form.find("input[name='_method']").val(), 'id' : $form.find("input[name='id']").val() },

            filters : {
                max_file_size : '32MB',
                mime_types: [
                    {title : "Image files", extensions : "jpg,gif,png"},
                    {title : "Zip files", extensions : "zip"}
                ]
            },

            flash_swf_url : '/plupload/js/Moxie.swf',
            silverlight_xap_url : '/plupload/js/Moxie.xap',


            init: {
                PostInit: function() {
                    $list.find('ul').html('');
                    $button.click(function () {
                        uploader.start();
                        Helper.loading({text: 'Nahrávám <span id="chunk-upload-progress">1</span> / '+$list.find('.count').text() });
                    });
                },

                FilesAdded: function(up, files) {
                    $list.removeClass('hidden');
                    var $count = $list.find('.count');
                    var $size = $list.find('.total-size');

                    var count = Number($count.text()), size = Number($size.text());
                    plupload.each(files, function(file) {

                        count++;
                        size += file.size;

                        $list.find('ul').append("<li class='list-group-item' id="+file.id+">"+file.name + '<span class="text-gray pull-right">' + plupload.formatSize(file.size) + '</span>'+"<span class='loading'></span>"+"</li>");
                        // console.log(file.name);
                    });

                    $count.text(count);
                    $size.text( Math.round(size / 1000000));
                },

                UploadProgress: function(up, file) {
                    $list.find('ul').find("li[id='"+file.id+"'] .loading").css('width', file.percent+"%");
                },

                FileUploaded: function(up, file) {
                    $("#chunk-upload-progress").text( Number($("#chunk-upload-progress").text()) + 1);
                    $list.find('ul').find("li[id='"+file.id+"'] .loading").addClass("done");
                    Helper.scroll({container:$(".list-group"), element: $("li[id='"+file.id+"']")});
                },

                UploadComplete: function () {
                    Helper.loading('close');
                    window.location.reload();
                },

                Error: function(up, err) {
                    console.error("Error #" + err.code + ": " + err.message);
                }
            }
        });

        uploader.init();
    });


    // SORTABLE
    if ($.fn.sortable) {
        $(".form-group ul.list-group.sortable").sortable({
            items: '.list-group-item',
            handle: '.handle'
        });
    } else {
        console.warn('Missing jQuery sortable plugin.');
    }

    // to JSON
    $.fn.toJson = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
});
